.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 180px;
  height: 180px;
  position: relative;
}

.rotatingBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px dotted #fff;
  border-radius: 50%;
  box-sizing: border-box;
  /* Supprimez les animations CSS */
}

.circleContent {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 30px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
