.testPage {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testZone {
  height: auto;
  width: auto;
  background-color: gray;
}
