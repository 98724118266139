@font-face {
  font-family: 'TitleFont';
  src: url('../assets/fonts/title.ttf') format('truetype');
}

.homePage {
  position: relative; // Ajoutez cette ligne
  height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 125px;
  box-sizing: border-box;
  gap: 115px;
}

.title {
  font-family: 'TitleFont', sans-serif;
  font-size: 100px;
  color: white;
}

.warning {
  font-size: 24px;
  color: red;
  text-align: center;
  padding: 20px;
}

.version {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  font-size: 12px; // Ajustez la taille selon vos préférences
  margin-right: 15px;
}
