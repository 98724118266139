.Menu {
  display: flex;
  position: absolute;
  top: 10px; /* Distance par rapport au haut de l'écran */
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 10px;
  margin-top: 8px;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(
    176,
    176,
    176,
    0.8
  ); /* Fond transparent pour discrétion */
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2); /* Ombre plus douce */
  z-index: 10; /* Premier plan */
  min-width: 160px;
  gap: 35px;
  transition: background-color 0.3s ease;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.iconsMenu {
  height: 25px;
  width: 25px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.iconsMenu:hover {
  transform: scale(1.1);
}

.rotate {
  animation: rotate360 1s ease;
}
