html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: black;
  overflow: hidden; /* Désactive le scroll */
}
